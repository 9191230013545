<template>
  <div class="main-container"  dir="ltr">
        <div class="row" dir="rtl">
            <div class="col-xl-6 col-md-6">
                <div class="my-account-content2" dir="rtl">
                    <div class="header-profile-login">
                        <div class="row justify-content-between">
                            <div class="col-10 col-sm-10 col-lg-10 col-xl-10">
                                <h6 class="text-center">تسجيل دخول</h6>
                            </div>
                            <div class="col-2 col-sm-2 col-lg-2 col-xl-2" style="padding-right: 0;">
                                <img style="width: 45px;float: left;margin-top: -18px;margin-left: -15px;" class="logo" src="static/images/logo.png" />
                            </div>
                        </div>
                        <form id="loginform" @submit.prevent="unsubscribe()">
                            <p class="login-submit">
                                <button class="button button-primary" style="width: 100%;background: #078CC5;">
                                    <span v-if="loading" class="px-1 arabickufi">جاري الغاء اشتراكك</span> &nbsp;
                                    <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                                    <span v-else class="arabickufi">الغاء الاشتراك</span>
                                </button>
                                <!-- <button type="submit" style="width: 100%;background: #078CC5;" name="wp-submit" id="wp-submit" class="button button-primary" value="إشتـــراك"></button> -->
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTPSPA } from '@/Api/http-Spay';
import { useToast } from "vue-toastification";
export default {
   name: 'AppUnsubscribe',
   data() {
     return {
       loading: false,
     };
   },
    mounted() {
       if (!this.$cookie.isCookieAvailable("msisdn") && !this.$cookie.isCookieAvailable("status")) {
          this.$router.push({ path: "/" })
       }
    },
   methods: {
      goBack() {
         return this.$router.go(-1)
      },
      async unsubscribe () {
        const toast = useToast();
          let button = document.querySelector(".button");
          button.disabled = true;
        this.loading = true;
        await HTTPSPA.post("SpayUnsubscribe.php?username="+this.$cookie.getCookie("msisdn")+"&serviceId=2222&opratorID=2").then((response) => {
          if (response.data.status === 0) {
            toast.error("انت لست مشترك في منصة كورساتي", { timeout: 2500 });
            this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
            this.$router.push('/')
          } else {
            toast.success("تم الغاء اشتراكك من منصة كورساتي ", { timeout: 2500 });
            this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
            this.$router.push('/')
          }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            this.loading = false;
        });
      },
   }
}
</script>

<style>

</style>